.PageTitle {
	font-size: 40px;
}

.PageTitle-Notebook {
	border-bottom-width: 1px;
}

.PageTitle-Pastel {
	color: var(--pastel-text-color);
}
