.Header {
	display: flex;
}

.Header-Notebook {
}

.Header-Pastel {
	background-color: white;
	border-bottom-width: var(--pastel-border-width);
	border-color: var(--pastel-color);
	border-left-width: 0;
	border-right-width: 0;
	border-top-width: 0;
	border-style: solid;
}
