.ActiveTag {
    display: flex;
    width: fit-content;
}

.ActiveTag-Notebook {
}

.ActiveTag-Pastel {
    background-color: var(--pastel-color) !important;
    border-radius: var(--pastel-border-radius);
    color: white;
    margin: 2px;
    padding: 5px;
}