@import url('https://fonts.googleapis.com/css2?family=Babylonica&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bona+Nova:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

html, body, #root {
    margin: 0;
    height: 100%;
    min-height: 100%;
}

:root {
    --notebook-font: "Babylonica";
    --notebook-color: #4a2a0a;
    
    --pastel-background-color: white;
    --pastel-border-radius: 20px;
    --pastel-border-width: 1px;
    --pastel-color: #119b41;
    --pastel-font: "Bona Nova";
    --pastel-highlight-color: #2bdb40;
    --pastel-opacity: 100%;
    --pastel-text-color: #09471f;
}