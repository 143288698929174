.InputLabel {
	padding: 10px;
}

.InputLabel-Notebook {
	font-size: 30px;
}

.InputLabel-Pastel {
	color: var(--pastel-text-color);
	font-size: 15px;
}
