.ImageUpload {
}

.ImageUpload-Notebook {
}

.ImageUpload-Pastel {
	border-style: solid;
    border-width: var(--pastel-border-width);
    width: 100%;
    margin: auto;
    height: fit-content;
    padding-top: 50px;
    padding-bottom: 50px;
}
