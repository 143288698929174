.Recipe {
    position: fixed;
    width: 50%;
    height: 75%;
    opacity: 100%;
    top: 10%;
}

.Recipe-Notebook {
}

.Recipe-Pastel {
	background-color: white;
    border-color: var(--pastel-color);
    border-radius: var(--pastel-border-radius);
	border-style: solid;
    border-width: var(--pastel-border-width);
	box-shadow: 0 0px 100px 0 rgba(1,1,1,1), 0 0px 100px 0 rgba(1,1,1,1);
    margin: auto;
    width: 75%;
}
