.ExistingTag {
    display: flex;
    width: fit-content;
}

.ExistingTag-Notebook {
}

.ExistingTag-Pastel {
    background-color: white;
    border-radius: var(--pastel-border-radius);
    font-family: inherit;
    margin: 2px;
    padding: 8px;
}