.RecipeTitle {
	font-size: 25px;
}

.RecipeTitle-Notebook {
	border-bottom-width: 1px;
}

.RecipeTitle-Pastel {
	color: var(--pastel-text-color);
	text-align: center;
	opacity: var(--pastel-opacity);
}
