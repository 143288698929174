.RecipePreview {
	margin: auto;
	width: 30%;
	margin-bottom: 5%;
}

.RecipePreview-Notebook {
}

.RecipePreview-Pastel {
    border: solid;
    border-color: var(--pastel-color);
    border-radius: var(--pastel-border-radius);
	border-width: var(--pastel-border-width);
	color: var(--pastel-text-color);
}

.RecipePreview-Pastel:hover {
	color: var(--pastel-text-color);
    border-color: var(--pastel-highlight-color);
	box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}