.EditButton {
    position: fixed;
    top: 15%;
    left: 15%;
    z-index: 1;
}

.EditButton-Notebook {
}

.EditButton-Pastel {
    border-color: var(--pastel-color);
}