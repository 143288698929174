.InputField, &:focus {
	outline: none;
}

.InputField-Notebook {
	font-family: var(--notebook-font);
	font-size: 25px;
	border-bottom-color: var(--notebook-color);
	border-bottom-width: 0px;
	border-top-width: 0;
	border-left-width: 0;
	border-right-width: 0;
	background: none;
	margin-bottom: 0;
}

.InputField-Pastel {
	background: none;
	border-bottom-color: var(--pastel-color);
	border-bottom-width: var(--pastel-border-width);
	border-left: none;
	border-right: none;
	border-top: none;
	font-family: var(--pastel-font);
	font-size: 15px;
}
