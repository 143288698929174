.NewRecipeButton-Notebook {
}

.NewRecipeButton-Pastel {
	font-family: var(--pastel-font);
	color: var(--pastel-text-color);
	background-color: white;
	border-left: none;
	border-top: none;
	border-bottom: none;
	border-radius: var(--pastel-border-radius);
	border-right-width: var(--pastel-border-width);
	border-right-color: var(--pastel-color);
	font-size: 15px;
}

.NewRecipeButton-Pastel:hover {
    color: var(--pastel-highlight-color);
	box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}
